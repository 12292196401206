<template>
  <div
  :class="(visitingProfile && profileActive) ? 'visiting-profile-w' : ''" class="avatar_navbar">
    <!-- avatar -->
    <figure
      @click="showMenu" class="
      ml-5
      pt-1
      image-
      is-hidden-touch
      is-flex">
      <img
        class="is-rounded image-avatar-panel"
        :src="usert.avatar" >
      <img
        class="arrow-avatar-nav-bar is-align-self-flex-end"
        src="@/assets/images/panel/avatarNavBar/arrow.svg"
        alt="">
    <!-- <figure
      @click="showMenu"
      class="ml-5 image is-32x32 is-hidden-touch is-flex">
      <img
        style="object-fit: cover;"
        class="is-rounded"
        :src="usert.avatar" >
      <img
        class="arrow-avatar-nav-bar is-align-self-flex-end"
        src="@/assets/images/panel/avatarNavBar/arrow.svg"
        alt=""> -->
      <div v-if="showp === true && approved === true" class="circle-available"></div>
      <div v-else-if="showp === true && approved === false" class="circle-review"></div>
      <div v-else class="circle-disable"></div>
    </figure>

    <b-navbar-item
      tag="div"
      class="logout logout-mobile"
      :class="(visitingProfile && profileActive) ? 'visiting-profile' : ''" :style="showScroll ? 'justify-content: flex-end;' : 'justify-content: space-between;'">
      <div
        v-if="visitingProfile && profileActive && !showScroll"
        class="is-hidden-desktop ml-4">
        <a
          href="/panel/me/edit-me">
          <img
            src="https://emituscdn.com/arrow-to+-back-public.svg"
            alt="">
        </a>
      </div>
      <!-- menu mobile -->
      <div @click="showMenu"  style="text-align: end;" class="is-hidden-desktop">
        <!-- <img src="@/assets/images/publicProfile/icon_profile_menu.svg" alt="icon"> -->
        <svg v-if="location !== '/' && !visitingProfile" width="7 " height="13" viewBox="0 0 5 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="1" cy="1.14979" rx="1" ry="1.00624" fill="#fff"/>
          <ellipse cx="1" cy="11.2122" rx="1" ry="1.00624" fill="#fff"/>
          <ellipse cx="1" cy="6.18104" rx="1" ry="1.00624" fill="#fff"/>
        </svg>
        <svg v-if="location === '/'" width="7" height="13" viewBox="0 0 2 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="1" cy="1.14979" rx="1" ry="1.00624" fill="#fff"/>
          <ellipse cx="1" cy="11.2122" rx="1" ry="1.00624" fill="#fff"/>
          <ellipse cx="1" cy="6.18104" rx="1" ry="1.00624" fill="#fff"/>
        </svg>
        <svg v-if="visitingProfile && !showScroll" width="7" height="13" viewBox="0 0 2 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="1" cy="1.14979" rx="1" ry="1.00624" fill="#fff"/>
          <ellipse cx="1" cy="11.2122" rx="1" ry="1.00624" fill="#fff"/>
          <ellipse cx="1" cy="6.18104" rx="1" ry="1.00624" fill="#fff"/>
        </svg>
        <svg v-if="visitingProfile && showScroll" width="7" height="13" viewBox="0 0 2 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="1" cy="1.14979" rx="1" ry="1.00624" fill="#fff"/>
          <ellipse cx="1" cy="11.2122" rx="1" ry="1.00624" fill="#fff"/>
          <ellipse cx="1" cy="6.18104" rx="1" ry="1.00624" fill="#fff"/>
        </svg>
      </div>
      <!-- avatar options box -->
      <div id="menu_logout_mobile" class="buttons disp-none btn-logout">
        <div v-if="location_1 === 'panel'">
          <div class=" is-flex is-align-items-center">
            <img class="mr-2" src="@/assets/images/money/card.svg" alt="my profile icon">
            <a @click="clickBankDetails" class="button mt-2 is-light text-basier">
              Ajustes de Banco
            </a>
          </div>
          <!-- link gray -->
          <div class="line-gray pb-4 pt-3"></div>
        </div>
        <!-- Bank Details -->

        <!-- change Password -->
        <div v-if="passwordAccount && location_1 === 'panel'" class=" is-flex is-align-items-center">
          <img class="mr-2" src="@/assets/images/panel/avatarNavBar/password.svg" alt="my profile icon">
          <a @click="resetPasswordModal" class="button mt-2 is-light text-basier">
            Nueva Contraseña
          </a>
        </div>
        <!-- Delete account -->
        <div
          v-if="location_1 === 'panel'" class="is-flex is-align-items-center">
          <img
            class="mr-2"
            src="https://emituscdn.com/icon-delete-menu-blue.svg"
            alt="my profile icon">
              <a
                target="_blank" href="https://api.whatsapp.com/send?phone='+34643943764'&text=Hi,%20I%20want%20to%20delete%20my%20account"
                class="button mt-3 is-light text-basier">
                Borrar la cuenta
              </a>
        </div>
        <!-- logout -->
        <div class="item-menu is-flex is-align-items-center">
          <img class="mr-2 img-logout" src="@/assets/images/panel/avatarNavBar/logout.svg" alt="logout">
          <a @click="logout" class="mt-2 button is-light text-basier">
            Cerrar Sesión
          </a>
        </div>
        <!-- go to my profile -->
        <div
          v-if="location_1 !== 'panel'"
          :class="location_2 === 'me' ? 'menu-my-profile' : ''"
          class="item-menu is-flex is-align-items-center">
          <img class="mr-2" src="@/assets/images/panel/avatarNavBar/profile-icon-mobile-black.svg" alt="my profile icon">
          <a href="/panel/me/edit-me" class="button is-light text-basier">
            Mi perfil
          </a>
        </div>
      </div>
    </b-navbar-item>

    <!-- section to change password -->
    <div id="change-password-modal" class="resetPasswordModal">
      <div>
        <!-- close section to change password -->
        <a @click="closeModalResetPassword">
          <div class="button-close has-text-right mr-5 mt-5">
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25 1L1 25"
                stroke="#9D9D9D"
                stroke-opacity="0.29"
                stroke-width="1.875"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 1L25 25"
                stroke="#9D9D9D"
                stroke-opacity="0.29"
                stroke-width="1.875"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </a>
        <!-- change password -->
        <div id="chance_password" class="container-content-create-account has-text-left mt-6">

          <div class="title-header-section-create-account">
            <p class="title-section text-basier has-text-left title-regular dark-blue-emitus">Cambiar contraseña</p>
          </div>
          <form class="form-login">
            <!-- old password  -->
            <b-field class="mt-5" label="Old Password">
              <b-input type="password" v-model="password"> </b-input>
            </b-field>
            <!-- new password -->
            <b-field class="mt-5" label="New Password">
              <b-input type="password" v-model="newPassword"> </b-input>
            </b-field>
            <!-- event to change password -->
            <button class="mt-6 button-primary-medium" @click.prevent="changePassword">Cambiar ahora</button>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import UserService from '@/services/UserService'
import firebase from 'firebase/app'
import 'firebase/auth'
import mixpanel from 'mixpanel-browser'

export default {
  name: 'AvatarNavBar',
  data () {
    return {
      password: '',
      newPassword: '',
      location: '',
      profileActive: false,
      showScroll: false,
      visitingProfile: false,
      bank: 'bank',
      usert: null
    }
  },
  props: {
    email: String,
    avatar: String,
    passwordAccount: Boolean,
    location_2: String,
    location_1: String,
    showp: Boolean,
    approved: Boolean
  },
  created () {
    this.notify.$on('profileActived', (data) => {
      this.profileActive = data
      // console.log(this.profileActive, '-----<')
    })
    window.addEventListener('scroll', this.scrollChangeMenuColor)
    this.notify.$on('visitingPublicProfile', () => {
      // console.log('estoy visitando un perfil')
      this.visitingProfile = true
      // console.log(this.visitingProfile, '-----<')
    })
    this.notify.$on('destroyedVisitingPublicProfile', () => {
      // console.log(' sali de el perfil visitando un perfil')
      this.visitingProfile = false
      // console.log(this.visitingProfile, '-----<')
    })
    this.notify.$on('updateUser', (user) => {
      this.usert = user
    })
    // Ask for data to App
    this.notify.$emit('giveMeData')
  },
  mounted () {
    this.location = window.location.pathname
  },

  // METHODS
  methods: {
    scrollChangeMenuColor () {
      const resp = window.scrollY
      if (resp > 900) {
        this.showScroll = true
        if (this.visitingProfile) {
          document.getElementById('avatar_navbar').classList.add('position-fixed')
          document.getElementById('avatar_navbar').classList.remove('position-abosolute')
        }
      } else {
        this.showScroll = false
        if (this.visitingProfile) {
          document.getElementById('avatar_navbar').classList.add('position-abosolute')
          document.getElementById('avatar_navbar').classList.remove('position-fixed')
        }
      }
    },
    showMenu () {
      document.getElementById('menu_logout_mobile').classList.toggle('disp-none')
    },
    // Logout -------
    async logout () {
      await firebase.auth().signOut()
      if (this.location_1 === '') {
        window.location.href = '/'
      } else {
        this.$router.push('/')
      }
      mixpanel.reset()
      // location.reload()
    },

    // Change password ----
    resetPasswordModal () {
      document.getElementById('change-password-modal').style.display = 'block'
    },

    // Close Modal Reset Pasword
    closeModalResetPassword () {
      document.getElementById('change-password-modal').style.display = 'none'
    },

    // Change Password -------
    async changePassword () {
      try {
        await firebase.auth().signInWithEmailAndPassword(this.usert.email, this.password)
        const user = firebase.auth().currentUser

        await user.updatePassword(this.newPassword)
        this.success('Password changed successfully')
      } catch (error) {
        this.danger(error.message)
      }
    },
    // bank details
    clickBankDetails () {
      this.notify.$emit('changeSectionAvatarBank', this.bank)
      this.$router.push('/panel/me/edit-me?section=bank')
    },
    danger (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-danger'
      })
    },
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    }
  },

  // DESTROY
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollChangeMenuColor)
    this.notify.$off('profileActived')
    this.notify.$off('visitingPublicProfile')
    this.notify.$off('destroyedVisitingPublicProfile')
    this.notify.$off('updateUser')
  }
}
</script>

<style scoped>
#chance_password .label,
#chance_password input::placeholder {
  color: #555!important;
}

.image-avatar-panel {
  width: 23px;
  height: 23px;
  object-fit: cover;
  border-radius: 30px;
}

.circle-available {
  width: 8px;
  height: 8px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #00D6CB;
  position: absolute;
  top: 5px;
  right: 27px;
  /* border: 2px solid; */
}
.circle-review {
  width: 8px;
  height: 8px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #ff9100;
  position: absolute;
  top: 10px;
  right: 25px;
}
.circle-disable {
  width: 8px;
  height: 8px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #FF1515;
  position: absolute;
  top: 4px;
  right: 27px;
  /* border: 2px solid; */
}

.line-gray {
  border-top: 1px solid #ECECEC;
  height: 3px;
  width: 80%;
}
.arrow-avatar-nav-bar {
  width: 8px;
  height: 7px;
}
.resetPasswordModal {
  display: none;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
}

.menu-my-profile {
    visibility: hidden;
}

.button {
    background-color: #fff !important;
}

.item-menu img {
    width: 20px;
}

.logout {
  float: right;
  margin-right: -9px;
  margin-top: -4px;
  padding-left: 26px;
  width: 40px;
}
.disp-none {
  display: none!important;
}
.btn-logout {
  top: 45px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 54px;
  position: absolute;
  right: 0px;
  width: 257px;
  background-color: #fff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.btn-logout a {
  background-color: #fff;
  color: #0D1239;
  font-size: 13.5px;
}

.title-header-section-create-account {
  margin-top: 73px;
  margin-left: 5%;
}

.form-login {
  margin-top: 70px;
}

.container-content-create-account {
  width: 85%;
}
.position-fixed {
  position: fixed!important;
}
.position-abosolute {
  position: absolute!important;
}
form {
  width: 90%;
  margin: auto;
  max-width: 360px;
}

.label:not(:last-child) {
    margin-bottom: 0.3em !important;
}

@media (min-width: 1024px) {
  .btn-logout {
    top: 29px;
  }
}
@media (max-width: 1024px) {

  .visiting-profile {
    width: 100%!important;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* position: absolute!important; */
  }
  .visiting-profile-w {
    width: 100%!important;
  }
}
</style>
