import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/bookings/edit/:idBooking/:username',
    name: 'StatusBooking',
    component: () => import('@/views/panel/StatusBooking.vue'),
    meta: {
      hasNavbar: true,
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'Listing',
    component: () => import('@/views/Listing.vue'),
    meta: {
      hasNavbarWhenLogged: true
    }
  },
  {
    path: '/soy-abogado',
    name: 'SellMyTime',
    component: () => import('@/views/Landing.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/views/Signup.vue'),
    meta: {
      onlyWithoutAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      onlyWithoutAuth: true
    }
  },
  {
    path: '/invest',
    name: 'Invest',
    component: () => import('@/views/Invest.vue'),
    meta: {
      onlyWithoutAuth: true
    }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import('@/views/Welcome.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/listing',
  //   name: 'Listing',
  //   component: () => import('@/views/Listing.vue'),
  //   meta: {
  //     requiresAuth: false
  //   }
  // },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/views/Terms.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy.vue')
  },
  // {
  //   path: '/404error',
  //   name: '404error',
  //   component: () => import('@/views/404.vue')
  // },
  {
    path: '/panel/me',
    name: 'Me',
    component: () => import('@/views/panel/Me.vue'),
    meta: {
      hasNavbar: true,
      requiresAuth: true,
      panel: true
    }
  },
  {
    path: '/panel/me/edit-me',
    name: 'edit-me',
    component: () => import('@/views/panel/Me.vue'),
    meta: {
      hasNavbar: true,
      requiresAuth: true,
      panel: true
    }
  },
  {
    path: '/panel/money',
    name: 'Money',
    component: () => import('@/views/panel/Wallet.vue'),
    meta: {
      hasNavbar: true,
      requiresAuth: true,
      panel: true
    }
  },
  {
    path: '/panel/booking',
    name: 'Booking',
    component: () => import('@/views/panel/Sessions.vue'),
    meta: {
      hasNavbar: true,
      requiresAuth: true,
      panel: true
    }
  },
  {
    path: '/panel/me/online-sessions',
    name: 'OnlineSessions',
    component: () => import('@/views/panel/me/onlineSessions.vue'),
    meta: {
      hasNavbar: true,
      requiresAuth: true,
      panel: true
    }
  },
  {
    path: '/3dsecure/:prebookingId',
    props: true,
    name: 'prebooking',
    component: () => import('@/views/payments3ds.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/404',
    props: true,
    name: 'NotFound',
    component: () => import('@/views/404.vue'),
    meta: {
      hasNavbarWhenLogged: true
    }
  },
  {
    path: '/:username',
    props: true,
    name: 'publicprofile',
    component: () => import('@/views/PublicProfile.vue'),
    meta: {
      hasNavbarWhenLogged: true,
      ispublicprofile: true
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => { // to: ruta actual, from: ruta anterior
  let authUser = null

  // check if user is signed in
  await firebase.auth().onAuthStateChanged(user => {
    authUser = user
  })

  // Validate conditions
  if (to.meta.onlyWithoutAuth && authUser) next({ name: 'Me' })
  else next()
})

export default router
