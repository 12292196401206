import axios from 'axios'
import MixpanelService from '@/services/MixpanelService'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {

  async getPublicProfile (username) {
    return await apiClient.get(`/profiles/${username}`)
  },

  async getAllPublicProfiles () {
    return await apiClient.get('/profiles/listing')
  },

  async getAllProfiles () {
    return await apiClient.get('/profiles/')
  },

  async getCurrentProfile (idToken) {
    return await apiClient.get('profiles/current',
      {
        headers: { idToken: idToken }
      })
  },

  async updateCurrentProfile (idToken, profile) {
    // console.log('updateProfileFront', profile)
    const response = await apiClient.post('profiles/current', profile,
      {
        headers: { idToken: idToken }
      })
    MixpanelService.updateProfileMixpanel(response, profile)

    return response
  },

  async approveProfile (idToken) {
    console.log('approveProfile')
    const response = await apiClient.post('profiles/aprove', {},
      {
        headers: { idToken: idToken }
      })

    return response
  }

}
