<template>

  <div id="missing_info" class="Modal">
    <div class="container-content-create-account has-text-left mt-6">
      <!-- greet user -->
      <div class="title-header-section-create-account pt-5">
        <p
          class="
            title-section
            text-basier
            has-text-left
            title-regular
            dark-blue-emitus<
          "
        >
          Antes de comenzar...
        </p>
      </div>

      <p class="description-nunito dark-gray-emitus">
        Completa la siguiente información para crear tu billetera virtual y puedas activar los servicios en la plataforma.</p>

      <form class="mt-6">

        <!-- Name -->
        <div class="name-user is-flex mt-5" v-if="showFirst || showLast">

          <!-- Firstname -->
          <b-field
            label="Primer nombre"
            :message="messageFirst"
            :type="messageTypeFirst"
            v-if="showFirst"
            class="name"
          >
            <b-input
              id="firstname"
              v-model="firstname"
              autocomplete="off"
              placeholder="Tonny"
              v-on:focusout.native="ValidateFirst"
              v-on:focusin.native="clearValidateFirst"
            >
            </b-input>
          </b-field>

          <!-- Lastname -->
          <b-field
            class="ml-2 name"
            label="Apellido"
            :message="messageLast"
            :type="messageTypeLast"
            v-if="showLast"
          >
            <b-input
              id="lastname"
              v-model="lastname"
              autocomplete="off"
              placeholder="Stark"
              v-on:focusout.native="ValidateLast"
              v-on:focusin.native="clearValidateLast"
            >
            </b-input>
          </b-field>
        </div>

        <!-- email -->
        <b-field
          class="mt-6 pt-5"
          label="Email"
          :message="messageEmail"
          :type="messageTypeEmail"
          v-if="showEmail"
          >
          <b-input
            v-model="email"
            placeholder="tuemail@algo.com"
            id="email"
            v-on:focusout.native="ValidateEmail"
            v-on:focusin.native="clearValidateEmail"
            autocomplete="off"
            >
            </b-input>
        </b-field>

        <!-- phone -->
        <b-field
          class="mt-4"
          label="Teléfono móvil"
          :message="messagePhone"
          :type="messageTypePhone"
          v-on:focusout.native="ValidatePhone"
          v-on:focusin.native="clearValidatePhone"
          v-if="showPhone"
          autocomplete="off"
          >
          <vue-tel-input
            v-model="phone"
            v-bind="bindProps"
            id="phone"
            autocomplete="off"
            >
            </vue-tel-input>

        </b-field>

        <!-- Date of birthday-->
        <b-field
          class="date-of-birth mt-4"
          label="Fecha de nacimiento"
          :message="messageDateOfBirth"
          :type="messageTypeDateOfBirth"
          v-on:focusout.native="ValidateDateOfBirth"
          v-on:focusin.native="clearValidateDateOfBirth"
          v-if="showDateOfBirth">
          <b-datepicker
            v-model="dateOfBirth"
            placeholder="Click para seleccionar..."
            icon="calendar-today"
            :locale="locale"
            trap-focus
            editable
            :max-date="maxDate"
            id="dateOfBirth"
          >
          <!--- Validation max-date="maxDate (+18) no la pude jeje meter"-->
          </b-datepicker>
        </b-field>

        <!--- Country of resident-->
        <!-- <b-field
          class="mt-4"
          label="Dónde vives"
          :message="messageCountry"
          :type="messageTypeCountry"
          v-on:focusin.native="clearValidateCountry"
          v-if="showCountryOfResidence"
          >
          <b-select
            v-on:focusout.native="ValidateCountry"
            v-model="countryorigin"
            placeholder="País de residencia"
            icon="earth"
            expanded
            id="countryorigin"
          >
            <option v-if="countryorigin" disabled value="">{{countryorigin}}</option>
            <option v-else disabled value="">Elegir país</option>
            <optgroup label="Most common">
              <option value="United States">United States</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="Germany">Germany</option>
              <option value="France">France</option>
              <option value="Italy">Italy</option>
              <option value="Spain">Spain</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Japan">Japan</option>
              <option value="India">India</option>
              <option value="Netherlands">Netherlands</option>
              <option value="Poland">Poland</option>
              <option value="Canada">Canada</option>
            </optgroup>

            <optgroup label="Other">
              <option
                v-for="(country, idx) in countries"
                :key="idx"
                :value="country"
              >
                {{ country }}
              </option>
            </optgroup>
          </b-select>
        </b-field> -->

        <!--- Nationality-->
        <!-- <b-field
              class="mt-4"
              label="De dónde eres"
              :message="messageNationality"
              :type="messageTypeNationality"
              v-on:focusin.native="clearValidateNationality"
              v-if="showNationality"
              >
              <b-select
                class=""
                v-on:focusout.native="ValidateNationality"
                v-model="nationality"
                placeholder="Nacionalidad"
                icon="earth"
                expanded
                id="nationality"
              >
                <option v-if="countryorigin" disabled value="">{{countryorigin}}</option>
                <option v-else disabled value="">Elige un país</option>
                <optgroup label="Most common">
                  <option value="United States">United States</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="Germany">Germany</option>
                  <option value="France">France</option>
                  <option value="Italy">Italy</option>
                  <option value="Spain">Spain</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Japan">Japan</option>
                  <option value="India">India</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Poland">Poland</option>
                  <option value="Canada">Canada</option>
                </optgroup>

                <optgroup label="Other">
                  <option
                    v-for="(country, idx) in countries"
                    :key="idx"
                    :value="country"
                  >
                    {{ country }}
                  </option>
                </optgroup>
              </b-select>
        </b-field> -->

        <!--- Currency -->
        <!-- <b-field
              class="mt-4"
              label="Divisa"
              :message="messageCurrency"
              :type="messageTypeCurrency"
              v-on:focusin.native="clearValidateCurrency"
              v-if="showCurrency"
              >

              <b-select
                v-on:focusout.native="ValidateCurrency"
                v-model="currency"
                placeholder="Elige divisa"
                expanded
                id="currency"
              >
                <option v-if="currency" disabled value="">{{currency}}</option>
                <option class="gray-emitus-light" v-else disabled value="">Cambiar sivisa</option>
                <option value="EUR">Euro</option> -->
                <!-- <option value="USD">US Dollar</option> -->
              <!-- </b-select>
        </b-field> -->

        <!-- update user -->
        <button
          class="button-primary-mini mt-6"
          @click.prevent="updateUser"
        >Ok</button>

      </form>

    </div>
  </div>

</template>

<script>
import UserService from '@/services/UserService'
import MpService from '@/services/MpService'
import firebase from 'firebase/app'
import 'firebase/auth'
import dayjs from 'dayjs'
import { VueTelInput } from 'vue-tel-input'

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  name: 'MissingInfo',
  components: {
    VueTelInput
  },

  // DATA
  data () {
    const today = new Date()
    return {
      // Data form DB
      dateOfBirthDB: null,
      countryoriginDB: null,
      nationalityDB: null,
      user: null,
      authUser: null,

      // To be updated
      dateOfBirth: null,
      countryorigin: 'Spain',
      nationality: 'Spain',
      email: '',
      phone: '',
      firstname: '',
      lastname: '',
      currency: 'EUR',

      // Messages
      messageFirst: '',
      messageTypeFirst: '',
      messageLast: '',
      messageTypeLast: '',
      messageEmail: '',
      messageTypeEmail: '',
      messagePhone: '',
      messageTypePhone: '',
      messageDateOfBirth: '',
      messageTypeDateOfBirth: '',
      messageCountry: '',
      messageTypeCountry: '',
      messageNationality: '',
      messageTypeNationality: '',
      messageCurrency: '',
      messageTypeCurrency: '',

      // About data
      date: new Date(),
      maxDate: new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()),
      locale: undefined, // Browser locale,
      countries: [
        'Andorra',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahrein',
        'Belgium',
        'Benin',
        'Bhutan',
        'Brazil',
        'British Indian Ocean Territory',
        'Bulgaria',
        'Chile',
        'China',
        'Canada',
        'Costa Rica',
        'Cote Ivoire',
        'Comoros',
        'Croatia',
        'Curacao',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'Estonia',
        'Falkland Islands (Malvinas)',
        'Faroe Islands',
        'Fiji',
        'Finland',
        'France',
        'French Polynesia',
        'French Guiana',
        'Georgia',
        'Germany',
        'Gibraltar',
        'Greece',
        'Guadeloupe',
        'Honduras',
        'Hong Kong',
        'Hungary',
        'Iceland',
        'Ireland',
        'Israel',
        'Italy',
        'Japan',
        'Jordan',
        'Kenya',
        'Korea',
        'Kiribati',
        'Latvia',
        'Lesotho',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Mayotte',
        'Martinique',
        'Malta',
        'Mauritius',
        'Mexico',
        'Moldova',
        'Monaco',
        'Morocco',
        'Netherlands',
        'New Caledonia',
        'New Zealand',
        'Norway',
        'Oman',
        'Paraguay',
        'Peru',
        'Poland',
        'Portugal',
        'Puerto Rico',
        'Qatar',
        'Reunion',
        'Romania',
        'Rwanda',
        'Saint Pierre And Miquelon',
        'San Marino',
        'Saudi Arabia',
        'Saint Barthelemy',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'South Africa',
        'Solomon Islands',
        'Spain',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Taiwan',
        'Tanzania',
        'Thailand',
        'Togo',
        'Tonga',
        'Turkey',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Uruguay',
        'Vietnam',
        'Wallis And Futuna'
      ],

      // Show
      showDateOfBirth: true,
      showCountryOfResidence: true,
      showNationality: true,
      showEmail: false,
      showPhone: true,
      showFirst: true,
      showLast: true,
      showCurrency: true,

      // Operations
      valid: true,
      bindProps: {
        mode: 'international',
        preferredCountries: ['US', 'ES', 'DE', 'GB'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off'
      }
    }
  },

  // CREATED
  async created () {
    // Get User fromm this.notify
    this.notify.$on('updateUser', (user) => {
      this.user = user
      if (this.user.email && this.user.email !== undefined) {
        this.showEmail = false
      }
      if (this.user.phone && this.user.phone !== undefined) {
        this.showPhone = false
      }
      if (this.user.firstname && this.user.firstname !== undefined) {
        this.showFirst = false
      }
      if (this.user.lastname && this.user.lastname !== undefined) {
        this.showLast = false
      }
      if (this.user.currency && this.user.currency !== undefined) {
        this.showCurrency = false
      }
    })
    // Get AuthUser fromm this.notify
    this.notify.$on('updateAuthUser', (user) => {
      this.authUser = user
    })

    // Get About from this.notify
    this.notify.$on('sendAbout', about => {
      if (about.dateOfBirthDB && typeof about.dateOfBirthDB !== 'undefined') {
        this.dateOfBirthDB = dayjs(about.dateOfBirthDB).set('hour', 0).set('minute', 0).set('second', 0).set('milliseconds', 0).utc().toDate()
        this.showDateOfBirth = false
      }
      if (about.countryOfResidenceDB && about.countryOfResidenceDB !== undefined) {
        this.countryoriginDB = about.countryOfResidenceDB
        this.showCountryOfResidence = false
      }
      if (about.nationalityDB && about.nationalityDB !== undefined) {
        this.nationalityDB = about.nationalityDB
        this.showNationality = false
      }
    })
    this.notify.$emit('giveMeAbout')
  },

  // MOUNTED
  async mounted () {
    // Ask for data to App
    await this.notify.$emit('giveMeData')
  },

  // METHODS
  methods: {
    async updateUser () {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const obj = {}

      // firstname
      if (!this.user.firstname) {
        if (this.firstname) {
          obj.firstname = this.firstname
        } else {
          this.danger('No olvides tu nombre')
          return
        }
      }

      // lastname
      if (!this.user.lastname) {
        if (this.lastname) {
          obj.lastname = this.lastname
        } else {
          this.danger('No olvides tu apellido')
          return
        }
      }

      // email
      if (!this.user.email) {
        this.valid = true
        await this.ValidateEmail()
        if (this.valid) { obj.email = this.email } else {
          this.danger('Algo está mal en tu correo')
          return
        }
      }

      // phone
      if (!this.user.phone) {
        const phoneValid = await this.ValidatePhone()
        if (phoneValid) { obj.phone = this.phone } else {
          this.danger('No olvides tu teléfono')
          return
        }
      }

      // dateOfBirth
      if (!this.dateOfBirthDB) {
        if (this.dateOfBirth) {
          const dateOfBirthOk = dayjs(this.dateOfBirth).utc(true).toDate()
          obj.dateofbirth = dateOfBirthOk
        } else {
          this.danger('No olvides tu fecha de nacimiento')
          return
        }
      }

      // Country of residence
      if (!this.countryoriginDB) {
        if (this.countryorigin) {
          obj.countryorigin = this.countryorigin
        } else {
          this.danger('No olvides tu país de residencia')
          return
        }
      }

      // Nationality
      if (!this.nationalityDB) {
        if (this.nationality) {
          obj.nationality = this.nationality
        } else {
          this.danger('No olvides tu nacionalidad')
          return
        }
      }

      // Currency
      if (!this.user.currency) {
        if (this.currency) {
          obj.currency = this.currency
        } else {
          this.danger('No olvides elegir moneda')
          return
        }
      }

      try {
        await UserService.updateUser(idToken, obj)
          .then(() => {
            MpService.createMpAccountAndWallet(idToken)
            this.notify.$emit('updateData')
            this.notification()
          })
      } catch (error) {
        this.danger(error)
      }
    },

    // Validations
    ValidateFirst () {
      if (!this.firstname) {
        this.messageFirst = 'Te falta este campo'
        this.messageTypeFirst = 'is-primary'
        document.getElementById('firstname').classList.add('invalid-input')
        return false
      } else {
        this.messageFirst = ''
        this.messageTypeFirst = ''
        return true
      }
    },

    ValidateLast () {
      if (!this.lastname) {
        this.messageLast = 'Te falta este campo'
        this.messageTypeLast = 'is-primary'
        document.getElementById('lastname').classList.add('invalid-input')
        return false
      } else {
        this.messageLast = ''
        this.messageTypeLast = ''
        return true
      }
    },

    async ValidateEmail () {
      if (!this.validEmail(this.email)) {
        this.messageEmail = 'Algo está mal. Revisa to correo electrónico.'
        this.messageTypeEmail = 'is-primary'
        this.valid = false
      } else {
        try {
          const response = await firebase.auth().fetchSignInMethodsForEmail(this.email)
          if (response.length > 0 && this.email !== this.user.email) {
            this.messageEmail = 'Este correo ya está registrado'
            this.messageTypeEmail = 'is-primary'
            this.valid = false
          } else {
            this.messageEmail = ''
            // this.messageTypeEmail = 'is-success'
          }
        } catch (error) {
          this.messageEmail = error.message
          this.messageTypeEmail = 'is-primary'
          console.log('pp', error)
          this.valid = false
        }
      }
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },

    async ValidatePhone () {
      if (!this.phone) {
        this.messagePhone = 'No olvides tu número de teléfono'
        this.messageTypePhone = 'is-primary'
        document.getElementById('phone').classList.add('invalid-input')
        return false
      } else {
        this.messagePhone = ''
        this.messageType = ''
        return true
      }
    },

    ValidateDateOfBirth () {
      if (!this.dateOfBirth) {
        this.messageDateOfBirth = 'Te falta este campo'
        this.messageTypeDateOfBirth = 'is-primary'
        document.getElementById('dateOfBirth').classList.add('invalid-input')
        return false
      } else {
        this.messageDateOfBirth = ''
        this.messageTypeDateOfBirth = ''
        return true
      }
    },

    ValidateCountry () {
      if (!this.countryorigin) {
        this.messageCountry = 'Te falta este campo'
        this.messageTypeCountry = 'is-primary'
        document.getElementById('countryorigin').classList.add('invalid-input')
        return false
      } else {
        this.messageCountry = ''
        this.messageTypeCountry = ''
        return true
      }
    },

    ValidateNationality () {
      if (!this.nationality) {
        this.messageNationality = 'Te falta este campo'
        this.messageTypeNationality = 'is-primary'
        document.getElementById('nationality').classList.add('invalid-input')
        return false
      } else {
        this.messageNationality = ''
        this.messageTypeNationality = ''
        return true
      }
    },

    ValidateCurrency () {
      if (!this.currency) {
        this.messageCurrency = 'Te falta este campo'
        this.messageTypeCurrency = 'is-primary'
        document.getElementById('currency').classList.add('invalid-input')
        return false
      } else {
        this.messageCurrency = ''
        this.messageTypeCurrency = ''
        return true
      }
    },

    // Notifications
    danger (msg) {
      this.$buefy.toast.open({
        duration: 3000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },

    notification () {
      this.$buefy.toast.open({
        duration: 1000,
        message: 'Guardado',
        position: 'is-top-right',
        type: 'is-success'
      })
    },

    // Clear Validations

    clearValidateFirst () {
      this.messageFirst = ''
      this.messageTypeFirst = ''
      document.getElementById('firstname').classList.remove('invalid-input')
    },

    clearValidateLast () {
      this.messageLast = ''
      this.messageTypeLast = ''
      document.getElementById('lastname').classList.remove('invalid-input')
    },

    clearValidateEmail () {
      this.messageTypeEmail = ''
      this.messageEmail = ''
    },

    clearValidatePhone () {
      this.messageTypePhone = ''
      this.messagePhone = ''
      document.getElementById('phone').classList.remove('invalid-input')
    },

    clearValidateDateOfBirth () {
      this.messageDateOfBirth = ''
      this.messageTypeDateOfBirth = ''
      document.getElementById('dateOfBirth').classList.remove('invalid-input')
    },

    clearValidateCountry () {
      this.messageCountry = ''
      this.messageTypeCountry = ''
      document.getElementById('countryorigin').classList.remove('invalid-input')
    },

    clearValidateNationality () {
      this.messageNationality = ''
      this.messageTypeNationality = ''
      document.getElementById('nationality').classList.remove('invalid-input')
    },

    clearValidateCurrency () {
      this.messageCurrency = ''
      this.messageTypeCurrency = ''
      document.getElementById('currency').classList.remove('invalid-input')
    }

  },

  // BEFORE DESTROY
  beforeDestroy () {
    this.notify.$off('updateAuthUser')
    this.notify.$off('updateUser')
    this.notify.$off('sendAbout')
  }
}
</script>

<style>
@import "../../../node_modules/vue-tel-input/dist/vue-tel-input.css";
#missing_info .name {
  width: 100%;
}

#missing_info input::placeholder {
  color: #D2D2D2!important;
  font-family: "Nunito";
  font-size: 14px;
}

#missing_info .select select::placeholder {
  color: #D2D2D2!important;
  font-family: "Nunito";
  font-size: 14px;
}
#missing_info input:-webkit-autofill,
#missing_info input:-webkit-autofill:hover,
#missing_info input:-webkit-autofill:active,
#missing_info input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    box-shadow: 0 0 0 1000px white inset;
}

</style>
