import axios from 'axios'
import MixpanelService from '@/services/MixpanelService'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  async SignupFirebase (firstname, lastname, email, uid, timezone) {
    return await apiClient.post('/auth/signup', {
      firstname: firstname,
      lastname: lastname,
      email: email,
      uid: uid,
      timezone: {
        zone: timezone
      }
    })
  },

  async ContinueWith (idToken, name, email, uid, timezone) {
    const response = await apiClient.post('/auth/loginWithToken', {
      idToken: idToken,
      timezone: {
        zone: timezone
      }
    })

    if (response.data.action === 'signup') {
      const responseSignUp = await apiClient.post('/auth/signup', {
        email: email,
        uid: uid,
        timezone: {
          zone: timezone
        }
      })
      await apiClient.post('profiles/current',
        {
          name: name
        },
        {
          headers: { idToken: idToken }
        })
      return responseSignUp
    } else {
      return response
    }
  },

  async SigninFirebase (idToken, timezone) {
    // console.log('gagaga', idToken)
    return await apiClient.post('/auth/loginWithToken', {
      idToken: idToken,
      timezone: {
        zone: timezone
      }
    })
  },

  async updateUser (idToken, user) {
    // console.log('--> User in UserService', user)
    const response = await apiClient.post('/users/update', user,
      {
        headers: { idToken: idToken }
      })
    MixpanelService.updateUserMixpanel(response, user)

    return response
  },

  async updateSessions (idToken, active) {
    return await apiClient.post('/users/update',
      {
        monetization: {
          sessions: active
        }
      },
      {
        headers: { idToken: idToken }
      })
  },

  async getCurrentUser (idToken) {
    return await apiClient.get('users/current',
      {
        headers: { idToken: idToken }
      })
  }
}
