<template>
  <div id="app">
    <!-- <div class="testing_shit">
      dfgj
    </div> -->

    <!-- NavBar -->
    <NavBar
      v-if="showNavbar"
      :key="keyNavBar">
    </NavBar>

    <!-- App -->
    <router-view  />

    <!-- Continue Modal -->
    <b-modal
        v-model="showContinue"
        full-screen
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal
        :can-cancel="false">

        <div>
          <Continue
            @close="showContinue = false"
          />
        </div>

    </b-modal>

    <!-- Rol Modal -->
    <b-modal
        v-model="showRol"
        full-screen
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal
        :can-cancel="false">

        <div>
          <Rol
            @close="showRol = false"
          />
        </div>

    </b-modal>


    <!-- Missing Info Modal -->
    <b-modal
        v-model="showMissingInfoModal"
        full-screen
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal
        :can-cancel="false">

        <div>
          <MissingInfo
            @close="showMissingInfoModal = false"
          />
        </div>

    </b-modal>

    <!-- loader -->
    <!-- <div v-if="showLoading">
      <Loading/>
    </div> -->

  </div>
</template>

<script>
import firebaseConfig from '@/firebase/init.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import NavBar from '@/components/panel/navbar.vue'
import Continue from '@/components/modals/continue.vue'
import Rol from '@/components/modals/rol.vue'
import MissingInfo from '@/components/modals/missinginfo.vue'
import UserService from '@/services/UserService'
import ProfileService from '@/services/ProfileService'
import MpService from '@/services/MpService'

export default {
  name: 'App',
  components: {
    // AppLayout,
    NavBar,
    Continue,
    MissingInfo,
    Rol
    // Loading
  },
  data () {
    return {
      showNavbar: false,
      showContinue: false,
      showRol: false,
      showMissingInfoModal: false,
      authUser: null,
      requiresAuth: false,
      user: {},
      profile: {},
      routeMeta: null,
      keyNavBar: 0,
      showLoading: false,
      rol: null,
      vh: window.innerHeight * 0.01
    }
  },

  // COMPUTED
  // cumputed: {
  //   vhhh: function () {
  //     // return window.innerHeight * 0.01
  //     return this.keyNavBar * 4
  //   }
  // },

  created () {
    // this.notify.$on('loadingInit', () => {
    //   this.showLoading = true
    // })
    // this.notify.$on('hiddeModalLoading', () => {
    //   this.showLoading = false
    // })
    // Get user from Firebase
    firebase.auth().onAuthStateChanged(user => {
      this.authUser = user
      this.getUserAndProfile()
    })

    // Listen to components that need data
    this.notify.$on('giveMeData', () => {
      this.notify.$emit('updateAuthUser', this.authUser)
      this.notify.$emit('updateUser', this.user)
      this.notify.$emit('updateProfile', this.profile)
    })
    this.notify.$on('giveMeAbout', () => {
      const about = {
        dateOfBirthDB: this.user.dateofbirth,
        countryOfResidenceDB: this.user.countryorigin,
        nationalityDB: this.user.nationality,
        currencyDB: this.user.currency
      }
      this.notify.$emit('sendAbout', about)
    })
    this.notify.$on('refreshNavBar', () => {
      this.keyNavBar++
    })

    this.getUserAndProfile()

    // Update Data
    this.notify.$on('updateData', () => {
      // console.log('bb')
      this.getUserAndProfile()
    })

    // Close modal
    this.notify.$on('hiddeModalRol', data => {
      this.showRol = data
    })

    // Listening for chages in viewport
    window.addEventListener('resize', this.reportWindowSize)
  },

  // WATCH
  watch: {
    // vh: function () {
    //   document.documentElement.style.setProperty('--vh', `${this.vh}px`)
    //   console.log('----> Updated vh', this.vh)
    // },

    async $route (to, from) {
      // console.log('--->>> route.to', to.meta)
      this.routeMeta = to.meta

      // Requieres Auth
      if (to.meta.requiresAuth) {
        this.requiresAuth = true
      } else {
        this.requiresAuth = false
      }

      // NavBar
      if (to.meta.hasNavbar) {
        this.showNavbar = true
      } else if (to.meta.hasNavbarWhenLogged && this.authUser) {
        if (screen.width >= 1024) {
          this.showNavbar = true
        }
      } else {
        this.showNavbar = false
      }

      // Continue with
      if (to.meta.requiresAuth && !this.authUser) {
        // console.log('--> qqqqqqqq', to.meta, this.authUser)
        this.showContinue = true
      } else {
        this.showContinue = false
      }

      // It trigger rol modal when is not defined; and get the rol for hiding staff when client
      if (to.meta.panel) {
        try {
          const idToken = await firebase.auth().currentUser.getIdToken(true)
          const user = await UserService.getCurrentUser(idToken)
          console.log('--> Entramos a la prueba de Roles', user)
          if (user && typeof user.data.rol !== 'undefined' && user.data.rol.length > 0) {
            console.log('Sabemos el rol')
            this.rol = user.data.rol
            console.log('hhjjkk', this.rol)

            // get the rol for hiding staff when client
            setTimeout(() => {
              if (this.rol === 'Abogado') {
                const servicesCollapse = document.getElementById('sessions_id')
                const elem1 = document.getElementById('only-professional-1')
                const elem2 = document.getElementById('only-professional-2')
                const elem6 = document.getElementById('btn_codivy')
                const elem3 = document.getElementById('only-professional-3')
                const elem4 = document.getElementById('only-professional-4')
                const elem5 = document.getElementById('navSideProfile4')
                if (servicesCollapse !== null) {
                  servicesCollapse.style.display = ''
                  elem1.style.display = ''
                  elem2.style.display = ''
                  elem3.style.display = ''
                  elem4.style.display = ''
                  elem5.style.display = ''
                  elem6.style.display = ''
                }
              } else if (this.rol === 'Cliente') {
                const servicesCollapse = document.getElementById('sessions_id')
                const elem1 = document.getElementById('only-professional-1')
                const elem2 = document.getElementById('only-professional-2')
                const elem6 = document.getElementById('btn_codivy')
                const elem3 = document.getElementById('only-professional-3')
                const elem4 = document.getElementById('only-professional-4')
                const elem5 = document.getElementById('navSideProfile4')
                if (servicesCollapse !== null) {
                  servicesCollapse.style.display = 'none'
                  elem1.style.display = 'none'
                  elem2.style.display = 'none'
                  elem3.style.display = 'none'
                  elem4.style.display = 'none'
                  elem5.style.display = 'none'
                  elem6.style.display = 'none'
                }
              }
            }, 3000)
          } else {
            this.showRol = true
          }
        } catch (error) {
          console.log(error)
        }
      }
    
    },

    authUser: function () {
      // if (!this.authUser && this.requiresAuth) {
      //   this.showContinue = true
      // } else {
      //   this.showContinue = false
      // }

      if (!this.authUser) {
        this.user = {}
        this.profile = {}
        this.notify.$emit('updateAuthUser', null)
        this.showNavbar = false
      }
      // else {
      //   setTimeout(() => {
      //     // Get Current User & Profile
      //     firebase.auth().currentUser.getIdToken(true)
      //       .then(idToken => {
      //         console.log('------------> BB')
      //         UserService.getCurrentUser(idToken)
      //           .then(user => {
      //             this.user = user.data
      //           })
      //         console.log('------------> BBB')
      //         ProfileService.getCurrentProfile(idToken)
      //           .then(resp => {
      //             this.profile = resp.data
      //           })
      //         this.notify.$emit('updateAuthUser', this.authUser)
      //       })
      //   }, 2000)
      // }

      // NavBar
      if (this.routeMeta && this.routeMeta.hasNavbar) {
        this.showNavbar = true
      } else if (this.routeMeta && this.routeMeta.hasNavbarWhenLogged && this.authUser) {
        this.showNavbar = true
      } else {
        this.showNavbar = false
      }
    },
    user: async function () {
      // Send User Infor to subscribed components
      if (!this.user) {
        this.notify.$emit('updateUser', {})
      } else {
        this.notify.$emit('updateUser', this.user)
        this.notify.$emit('sendAbout', {
          dateOfBirthDB: this.user.dateofbirth,
          countryOfResidenceDB: this.user.countryorigin,
          nationalityDB: this.user.nationality,
          currencyDB: this.user.currency
        })
      }

      // Show Missing info modal when needed
      this.showMissingInfoModal = false
      if (typeof this.user.monetization !== 'undefined' && this.user.monetization.sessions) {
        if (
          this.user &&
          typeof this.user.phone !== 'undefined' && this.user.phone.length > 0 &&
          typeof this.user.email !== 'undefined' && this.user.email.length > 0 &&
          typeof this.user.firstname !== 'undefined' && this.user.firstname.length > 0 &&
          typeof this.user.lastname !== 'undefined' && this.user.lastname.length > 0 &&
          typeof this.user.countryorigin !== 'undefined' && this.user.countryorigin.length > 0 &&
          typeof this.user.dateofbirth !== 'undefined' && this.user.dateofbirth &&
          typeof this.user.nationality !== 'undefined' && this.user.nationality.length > 0) {
          this.showMissingInfoModal = false
          if (typeof this.user.mangopayuserid !== 'undefined' && this.user.mangopayuserid.length > 0) {
            console.log('222')
          } else {
            try {
              const idToken = await firebase.auth().currentUser.getIdToken(true)
              const obj = {
                firstname: this.user.firstname,
                lastname: this.user.lastname,
                email: this.user.email,
                phone: this.user.phone,
                dateofbirth: this.user.dateofbirth,
                countryorigin: this.user.countryorigin,
                nationality: this.user.nationality,
                currency: 'EUR'
              }
              await UserService.updateUser(idToken, obj)
                .then(() => {
                  MpService.createMpAccountAndWallet(idToken)
                  this.notify.$emit('updateData')
                  this.notification()
                })
            } catch (error) {
              console.log('error')
              // this.danger(error)
            }
          }
        } else {
          this.showMissingInfoModal = true
        }
      }
    },

    profile: function () {
      if (!this.profile) {
        this.notify.$emit('updateProfile', {})
      } else {
        this.notify.$emit('updateProfile', this.profile)
      }
    }

    // showNavbar: function () {
    //   if (this.showNavbar && window.innerWidth < 700) {
    //     document.getElementById('fc_frame').classList.add('chatUp')
    //   } else {
    //     document.getElementById('fc_frame').classList.remove('chatUp')
    //   }
    // }

  },

  // MOUNTED
  async mounted () {
    console.log('MOUNTED')
  },

  // METHODS
  methods: {
    // Update VH
    reportWindowSize () {
      console.log('---------------------------------------------------------------- AAA', this.vh)
      this.vh = window.innerHeight * 0.01
      console.log('---------------------------------------------------------------- BBB', this.vh)
      document.documentElement.style.setProperty('--vh', `${this.vh}px`)
    },

    // Get Current User & Profile
    async getUserAndProfile () {
      if (this.authUser) {
        firebase.auth().currentUser.getIdToken(true)
          .then(async idToken => {
            // console.log('------------> AA')
            await UserService.getCurrentUser(idToken)
              .then(user => {
                this.user = user.data
                // console.log('------------> TT', this.user)
                this.notify.$emit('gato', this.user)
                this.notify.$emit('perro', this.user)
                this.notify.$emit('updateUser', this.user)
              })
            // console.log('------------> AAA')
            await ProfileService.getCurrentProfile(idToken)
              .then(resp => {
                this.profile = resp.data
                this.notify.$emit('updateProfile', this.profile)
              })
            this.notify.$emit('updateAuthUser', this.authUser)
            this.notify.$emit('refreshNavBar')
            this.notify.$emit('refreshMyProfile')
          })
      }
    },

    // Notifications
    danger (msg) {
      this.$buefy.toast.open({
        duration: 3000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  },

  // DESTROY
  beforeDestroy () {
    this.notify.$off('giveMeData')
    this.notify.$off('giveMeAbout')
    this.notify.$off('refreshNavBar')
    this.notify.$off('updateData')
    this.notify.$off('hiddeModalRol')
  }
}

</script>

<style>
/* .testing_shit {
  height: 65px;
  background-color: red;
} */

@media (max-width: 1024px) {
  .chatUp {
    bottom: 88px !important;
  }
}
@media (min-width: 1024px) {
  .chatUp {
    bottom: 15px !important;
  }
}
</style>
