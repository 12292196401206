<template>
  <div>
    <!-- Close Modal -->
    <a @click="takeMeBack">
      <div class="button-close has-text-right mr-5 mt-5">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 1L1 25"
            stroke="#9D9D9D"
            stroke-opacity="0.29"
            stroke-width="1.875"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 1L25 25"
            stroke="#9D9D9D"
            stroke-opacity="0.29"
            stroke-width="1.875"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </a>

    <!-- box to enter as user -->
    <div
      id="join_"
      class="
      join-position-mobile
      join-container-mobile
      join-found-mobile join-w-full
      join-h-full
      is-flex
      is-flex-direction-column
      is-justify-content-space-between
      w-100"
    >
      <div
      class="
      w-100
      has-text-right
      btn-skip-table
      pt-4
      is-hidden-tablet">
      </div>
      <div
        class="
        join-bg-transparent
        container-login
        m-center
        border-container
        is-flex
        is-flex-direction-column"
      >
        <div
          id="join_container"
          class="
          container
          is-max-desktop
          not-w-content
          pb-3
          text-color">

          <!-- title -->
          <h3
            class="
            join-display-none
            join-title
            text-basier
            mt-4
            pb-3
            has-text-weight-normal
            has-text-centered
            dark-blue-emitus"
          >
            Te damos la bienevenida <br> a Abogadus.com
          </h3>

          <p
            class="join-display-none dark-gray-emitus mt-1 paragraph-content has-text-centered dark-blu-emitus paragraph-short"
          >
           Para brindarte una mejor experiencia
          </p>

          <div
            class="buttons-joins buttons align-content-center is-flex not-w-btn is-flex-direction-column container is-max-desktop z-index-full"
          >

          <button class="boton-black button-primary-abogadus" @click="saveRol('Abogado')">
            Eres Abogado
          </button>

          <button class="mt-3 button-primary-black-abogadus" @click="saveRol('Cliente')">
            Eres Cliente
          </button>

          </div>
        </div>
      </div>
    </div>
    <!-- loader -->
    <div v-if="loader">
      <Loading/>
    </div>
  </div>

</template>

<script>
import Loading from '@/components/modals/loader.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import UserService from '@/services/UserService'
import dayjs from 'dayjs'
import MixpanelService from '@/services/MixpanelService'

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'ContinueModal',
  components: {
    Loading
  },
  data: () => {
    return {
      authUser: null,
      checkbox: true,
      showContinue: true,
      showLogin: false,
      showSignup: false,
      email: '',
      password: '',
      user: {},
      rol: null,

      loader: false
    }
  },
  created () {
    firebase.auth().onAuthStateChanged(user => {
      this.authUser = user
      // console.log('--------> uyu', this.authUser)
    })
    // console.log(this.$router.history._startLocation)
    // console.log(this.$router.history.current.fullPath)
  },
  methods: {

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-danger'
      })
    },

    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },

    // Close modal
    closeModal () {
      this.$emit('close')
    },

    // Save rol
    async saveRol (rol) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      console.log('idToke', idToken)
      this.user.rol = rol
      let response = null
      response = await UserService.updateUser(idToken, this.user)
      console.log('response', response)
      if (response && response.statusText === 'OK') {
        this.success('Guardado')
        this.loader = false
        this.notify.$emit('hiddeModalRol', false)
        this.notify.$emit('updateData')
      }
    },

    // Take me back or home
    takeMeBack () {
      // console.log('click')
      console.log('tttttdddd', this.$router.history._startLocation)
      if (this.$router.history._startLocation === this.$router.history.current.fullPath) {
        this.notify.$emit('hiddeModalRol', false)
        this.$router.push('/')
      } else if (this.$router.history._startLocation === '/404') {
        this.notify.$emit('hiddeModalRol', false)
      } else if (this.$router.history._startLocation === '/') {
        this.notify.$emit('hiddeModalRol', false)
        this.$router.push('/')
        // this.notify.$emit('hiddeModalRol', false)
      } else {
        // console.log('click 3')
        history.go(-1)
      }
    }

  }
}
</script>

<style scoped>
/* #sign_up */
#join_ .label {
  color: #555!important;
}
#join_ input::placeholder {
  color: #D2D2D2!important;
}
#sign_up input:-webkit-autofill,
#sign_up input:-webkit-autofill:hover,
#sign_up input:-webkit-autofill:active,
#sign_up input:-webkit-autofill:focus {
    background-color: #FFFFFF !important;
    color: #555 !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: #555555 !important;
    box-shadow: 0 0 0 1000px white inset !important;
}
#sign_up .has_required {
  text-decoration: line-through;
  color:#6e3dea;
}
#sign_up form {
    margin-top: 30px;
    width: 90%;
    margin: auto;
    max-width: 360px;
}

#sign_up .title-header-section-create-account {
  margin-top: 35px;
  margin-bottom: 45px;
  margin-left: 5%;
}
#sign_up .container-content-create-account {
  width: 85%;
}

#sign_up input, #sign_up select {
    height: 45px !important;
}

#sign_up input::placeholder {
  font-family: 'Nunito' !important;
}

#sign_up input:hover {
  border-color: #EEEEEE;
}

.signup-with-button {
  transition: all 0.1s ease-in-out 0s;
}

.signup-with-button:hover, .signup-with-button:active, .signup-with-button:focus {
  transform: scale(1.04);
}
.form-login-continue {
  width: 287px;
}

.form-signin-continue {
  width: 287px;
}

.container-sign-in {
  font-size: 14px;
}
.validationCheck {
  border-bottom: 1px solid red;
  padding-bottom: 10px;
}
.buttons-joins {
  margin-top: 34px !important;
}

.signup-with-button {
  margin-top: 10px !important;
}

.link-text {
  text-decoration: underline;
  padding-bottom: 4px;
  transition: color 200ms ease;
}

.link-text:hover, .link-text:active, .link-text:focus {
   color:  rgba(13, 18, 57, 0.6)!important;
}

.link-cancel-button {
  font-size: 16px;
}

.signup-with-button {
  height: 45px !important;
  border-radius: 0px !important;
  margin-top: 15px;
}

.big-button {
  width: 220px !important;
}

#danger-line {
  border-radius: 10px;
  padding: 1px;
  margin-top: 25px;
}
#danger {
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
}

.paragraph-short {
  max-width: 356px;
  margin: auto;
}

.align-content-center {
  align-content: center;
}

.join-title {
  font-size: 28.5px;
}
.shadow-btn {
  border: 1px solid #000000 !important;
  /*box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08)!important;*/
}

.container-sign-in {
  margin-top: 10px;
  padding-top: 20px;
  border-top:1px solid #F3F3F3;
  text-align: center;
}

.join-container-mobile {
    margin-top: 134px;
  }
@media (max-width: 768px) {
  .join-container-mobile {
    margin-top: 84px;
  }

   .join-title {
     font-size: 24.5px;
   }

  .container {
    width: 91% !important;
  }
}

</style>
