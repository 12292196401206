import mixpanel from 'mixpanel-browser'
import UserService from '@/services/UserService'
import ProfileService from '@/services/ProfileService'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  async login (user) {
    const idToken = await firebase.auth().currentUser.getIdToken(true)
    const response = await ProfileService.getCurrentProfile(idToken)
    const profile = response.data

    mixpanel.identify(user._id)
    mixpanel.register({
      approved: profile.approved,
      show: profile.show
    })
  },

  async updateUserMixpanel (response, user) {
    // console.log('response 221', response)
    mixpanel.identify(response.data._id)
    mixpanel.track('Update Profile', user)
    mixpanel.people.set({
      $name: response.data.firstname + ' ' + response.data.lastname,
      $email: response.data.email,
      $avatar: response.data.avatar,
      $first_name: response.data.firstname,
      $last_name: response.data.lastname,
      $phone: response.data.phone,
      countryOfResidence: response.data.countryorigin
    })
  },

  async updateProfileMixpanel (response, profile) {
    console.log('response 331', response)

    mixpanel.identify(response.data.user_id)
    mixpanel.track('Update Profile', profile)
    mixpanel.people.set({
      occupation: response.data.title,
      description: response.data.description,
      languages: response.data.languages,
      skills: response.data.skills,
      experience: response.data.experience,
      degrees: response.data.education,
      linkedin: response.data.linkedin,
      facebook: response.data.facebook,
      instagram: response.data.instagram,
      twitter: response.data.twitter,
      tiktok: response.data.tiktok,
      show: response.data.show
    })
  },

  async showProfileMixpanel (UserId) {
    mixpanel.identify(UserId)
    mixpanel.track('Show Profile')
  },

  async signUp (newUser) {
    mixpanel.alias(`${newUser.data.User.id}`)
    mixpanel.track('Sign Up')
    mixpanel.people.set({
      $name: newUser.data.User.firstname + ' ' + newUser.data.User.lastname,
      $email: newUser.data.User.email,
      $distinct_id: newUser.data.User.id
    })
  },

  async bookingRequest (nameProfessional, booking) {
    // console.log('--> 00799 nameProfessional', nameProfessional)
    // console.log('--> 00788 booking', booking)
    mixpanel.alias(`${booking.client_id}`)
    mixpanel.track('Booking Request', {
      ProfesionalName: nameProfessional,
      ProfesionalID: booking.professional_id,
      StartTime: booking.startBook,
      Duration: booking.duration,
      Language: booking.language
    })
  },

  async bookingAproval (nameProfessional, booking) {
    console.log('--> 00799 nameProfessional', nameProfessional)
    console.log('--> 00788 booking', booking)
    mixpanel.alias(`${booking.professional_id}`)
    mixpanel.track('Booking Approval', {
      ProfesionalName: nameProfessional,
      ProfesionalID: booking.professional_id,
      StartTime: booking.startBook,
      Duration: booking.duration,
      Language: booking.language
    })
  }
}
