import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import '@/styles/index.scss'
import VueMeta from 'vue-meta'
import firebase from 'firebase/app'
import mixpanel from 'mixpanel-browser'
import '../public/main.css'

mixpanel.init(`${process.env.VUE_APP_MIXPANEL_TOKEN}`, { debug: true })

// export const serverBus = new Vue()
const notify = new Vue()
Vue.prototype.notify = notify

Vue.use(Buefy)
Vue.use(VueMeta)

let devtools = false
if (process.env.VUE_APP_DEVTOOLS === 'true') {
  devtools = true
}
// console.log('hhhd -->', process.env.VUE_APP_DEVTOOLS, devtools)

Vue.config.productionTip = false
Vue.config.devtools = devtools

let app = null

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})
