import axios from 'axios'
import qs from 'qs'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const apiBrowser = axios.create({
  baseURL: 'https://www.cloudflare.com/cdn-cgi/trace',
  withCredentials: false
})

const apiTokenization = axios.create({
  // baseURL: 'https://homologation-webpayment.payline.com/webpayment',
  baseURL: '',
  withCredentials: false,
  headers: {
    Accept: 'application/x-www-form-urlencoded',
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

export default {
  async createMpAccountAndWallet (idToken) {
    return await apiClient.post('/mp/account', {},
      {
        headers: { idToken: idToken }
      })
  },
  async getMyWallet (idToken) {
    return await apiClient.get('/mp/wallet',
      {
        headers: { idToken: idToken }
      })
  },
  async createCardRegistrationObject (idToken) {
    return await apiClient.post('/mp/cardRegistration', {},
      {
        headers: { idToken: idToken }
      })
  },
  async postCardInfo (obj, baseURL) {
    console.log('2376136 baseURL', baseURL)
    return await apiTokenization.post(`${baseURL}`, qs.stringify(obj))
  },
  async putTokenData (idToken, obj, CardRegId) {
    return await apiClient.put(`/mp/putTokenData/${CardRegId}`, obj,
      {
        headers: { idToken: idToken }
      })
  },
  async payIn (idToken, obj) {
    return await apiClient.post('/mp/payIn', obj,
      {
        headers: { idToken: idToken }
      })
  },
  async getMyCards (idToken) {
    return await apiClient.get('/mp/myCards',
      {
        headers: { idToken: idToken }
      })
  },
  async deleteCard (idToken, cardId) {
    return await apiClient.put(`/mp/delete/${cardId}`,
      {
        Active: false
      },
      {
        headers: { idToken: idToken }
      })
  },
  async getMyTransactions (idToken) {
    return await apiClient.get('/mp/myTransactions',
      {
        headers: { idToken: idToken }
      })
  },
  async createIBANBankAccount (idToken, obj) {
    return await apiClient.post('/mp/bankAcounts', obj,
      {
        headers: { idToken: idToken }
      })
  },
  async createUSBankAccount (idToken, obj) {
    return await apiClient.post('/mp/USBankAcounts', obj,
      {
        headers: { idToken: idToken }
      })
  },
  async createGBBankAccount (idToken, obj) {
    return await apiClient.post('/mp/GBBankAcounts', obj,
      {
        headers: { idToken: idToken }
      })
  },
  async createCABankAccount (idToken, obj) {
    return await apiClient.post('/mp/CABankAcounts', obj,
      {
        headers: { idToken: idToken }
      })
  },
  async createOTHERBankAccount (idToken, obj) {
    return await apiClient.post('/mp/OTHERBankAcounts', obj,
      {
        headers: { idToken: idToken }
      })
  },

  async createKYC (idToken) {
    return await apiClient.post('/mp/kyc/create', {},
      {
        headers: { idToken: idToken }
      })
  },
  async addPageKYC (idToken, file, KYCDocumentId) {
    return await apiClient.post('/mp/kyc/addPage',
      {
        file: file,
        KYCDocumentId: KYCDocumentId
      },
      {
        headers: { idToken: idToken }
      })
  },
  async submitKYC (idToken, KYCDocumentId) {
    return await apiClient.put('/mp/kyc/submit',
      {
        KYCDocumentId: KYCDocumentId
      },
      {
        headers: { idToken: idToken }
      })
  },
  async preAuth (idToken, obj) {
    return await apiClient.post('/mp/preAuth', obj,
      {
        headers: { idToken: idToken }
      })
  },
  async getIpAddress () {
    return await apiBrowser.get('/')
  },
  async getMyBankAccounts (idToken) {
    return await apiClient.get('/mp/bankAccounts',
      {
        headers: { idToken: idToken }
      })
  },
  async createPayout (idToken, obj) {
    return await apiClient.post('/mp/payout', obj,
      {
        headers: { idToken: idToken }
      })
  },
  async getPreAuth (idToken, mppreauthid) {
    return await apiClient.get(`/mp/preAuth/${mppreauthid}`,
      {
        headers: { idToken: idToken }
      })
  },
  async deleteBankAccount (idToken, BankAccountId) {
    return await apiClient.put(`/mp/bankAccounts/${BankAccountId}`, {},
      {
        headers: { idToken: idToken }
      })
  }
}
