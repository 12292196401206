<template>

  <div v-if="authUser" >

    <b-navbar
    id="nav_bar"
    class="nav_bar_profile"
    :mobile-burger="false"
    >

    <template #start>
        <div class="is-justify-content-space-between is-flex is-align-items-center" style="width: 100%;">

          <!-- Logo -->
          <div class="is-flex is-align-items-center" style="gap: 30px;">
            <div class="ml-5 is-hidden-touch">
              <router-link
              to="/"><img class="logo-abogadus-nav-bar-me" src="https://emituscdn.com/LOGO-ABOGADUS..svg"
              alt="logo abogadus">
              </router-link>
            </div>
            <div v-if="location_1 != ''" id="search_id_navbar" class="search-header-listing is-hidden-touch">
              <section class="relative-listing-mobile">
                <b-field>
                  <b-autocomplete
                    rounded
                    name="searchBarNavbar"
                    id="searchBarNavbar"
                    placeholder="Mercantil, civil, coorporativo.."
                    icon="magnify"
                    v-on:focusin.native="pushListing"
                    >
                  </b-autocomplete>
                </b-field>
              </section>
            </div>
          </div>

          <!-- Menu -->
          <div
            style="gap: 19px;"
            class="is-flex is-align-items-center container-navbar-item container-icon-navbar">
            <!-- search -->
            <div style="height: 100%; width: 100%;">
              <b-navbar-item
                id="Search"
                class="item-width is-flex is-is-align-items-center flex-direction-icon-navbar is-hidden-desktop"
                style="height: 100%; width: 100%;"
                tag="router-link"
                :to="{name: 'Listing'}"
              >
                <div>
                  <svg width="17" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.11115 15.2223C12.0385 15.2223 15.2223 12.0385 15.2223 8.11115C15.2223 4.18377 12.0385 1 8.11115 1C4.18377 1 1 4.18377 1 8.11115C1 12.0385 4.18377 15.2223 8.11115 15.2223Z" stroke="white" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M17 17L13.1333 13.1333" stroke="white" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <p class="text-nunito text-icon is-mini-small-text" >Abogados</p>
              </b-navbar-item>
            </div>

            <!-- Bookings -->
            <div style="height: 100%; width: 100%;">
              <b-navbar-item
                id="Bookings"
                class="item-width is-flex is-is-align-items-center flex-direction-icon-navbar"
                style="height: 100%; width: 100%;"
                tag="router-link"
                :to="{name: 'Booking'}"
              >
                <img
                    class="is-hidden-touch no-select"
                    src="https://emituscdn.com/sessions-white.svg"
                    alt=""
                    style="width: 30px;"
                >
                <img
                    class="is-hidden-desktop no-select"
                    src="https://emituscdn.com/sessions-white.svg"
                    alt=""
                    style="width: 30px;"
                >
                <!-- <img
                    class="si-select"
                    src="https://emituscdn.com/Sessions-blue.svg"
                    alt=""
                    width="27px"
                    style=" margin-bottom: 1px;"
                > -->
                <p class="text-nunito text-icon is-mini-small-text">Sesiones</p>
              </b-navbar-item>
            </div>

            <!-- Money -->
            <div v-if="showBilletera" style="height: 100%; width: 100%;">
              <b-navbar-item
                class="is-flex flex-direction-icon-navbar"
                style="height: 100%; width: 100%;"
                id="Monetize"
                tag="router-link"
                :to="{name: 'Money'}"
              >
                <img
                    class="is-hidden-touch no-select"
                    src="https://emituscdn.com/Wallet-white.svg"
                    alt=""
                    style="width: 27px; opacity: 0.8; margin-top: 1.6px; margin-bottom: 4.5px;"
                >
                <img
                    class="is-hidden-desktop no-select"
                    src="https://emituscdn.com/Wallet-white.svg"
                    alt=""
                    style=" width: 27px; opacity:0.8;"
                >
                <!-- <img
                    class="si-select"
                    src="https://emituscdn.com/Wallet-blue.svg"
                    alt=""
                    style="width: 27px; margin-bottom: 3px;"
                > -->
                <p
                  class=" text-nunito text-icon is-mini-small-text">
                  Billetera</p>
              </b-navbar-item>
            </div>

            <!-- Monetize -->
            <!-- <div style="height: 87%;">
              <b-navbar-item
                class="item-width is-flex is-flex-direction-column"
                style="height: 94%;"
                id="Inbox"
                tag="router-link"
                :to="{name: 'OnlineSessions'}"
              >
                <img
                    class="is-hidden-touch"
                    src="https://emituscdn.com/my-money-icon-white+(1).svg"
                    alt="Chat"
                    style="width: 20px; opacity: 0.8;"
                >
                <img
                    class="is-hidden-desktop no-select"
                    src="https://emituscdn.com/my-money-icon-blue+(1).svg"
                    alt="Chat"
                    style="width: 19px;"
                >
                <img
                    class="is-hidden-desktop si-select"
                    src="https://emituscdn.com/my-money-icon-purple+(1).svg"
                    alt="Chat"
                    style="width: 22px;"
                >
                <p style="margin-top: 1px;" class="text-nunito is-size-7 text-icon">Monetize</p>
              </b-navbar-item>
            </div> -->

            <!-- Me -->
            <div style="height: 100%; width: 100%;">
              <b-navbar-item
                class="select-profile item-width is-flex flex-direction-icon-navbar"
                style="height: 100%; width: 100%;"
                id="Me"
                tag="router-link"
                :to="{name: 'edit-me'}"
              >
                <img
                    class="is-hidden-touch no-select"
                    src="https://emituscdn.com/me-white.png"
                    alt="Profile"
                    style=" width: 27px;"
                >
                <!-- <img
                  class="si-select"
                  src="https://emituscdn.com/me-blue.svg"
                  alt="Profile"
                  style="width: 27px; z-index: 0;"
                > -->
                <img
                  class="is-hidden-desktop no-select"
                  src="https://emituscdn.com/me-white.png"
                  alt="Profile"
                  style="width: 27px; z-index: 0;"
                >
                <p
                  style="z-index: 0;"
                  class="select-profile text-nunito is-mini-small-text text-icon">Cuenta</p>
              </b-navbar-item>
            </div>

          </div>
        </div>
    </template>

    </b-navbar>

    <div
     v-if="location_2 && !location_3 && booking === null"
     class="title-panel-mobile is-hidden-desktop text-basier is-flex">
      <p
        v-if="location_2 === 'me'">
        Perfil
      </p>
      <p v-else>{{location_2}}</p>
      <!-- <p>{{location_2}}</p> -->
    </div>

    <!-- header mobile  -->
    <div
      v-if="location_3 && booking === null"
      class="title-panel-mobile is-hidden-desktop text-basier is-flex"
      >
      <img  v-if="location_3 === 'bank-details'" @click="historyBack" class="mr-3" src="@/assets/images/panel/navbar/back-header.svg" alt="">
      <p
        v-if="location_3 === 'edit-me'">
        Perfil
      </p>
      <p
        v-else-if="location_3 === 'online-sessions'">
        Monetización
      </p>
      <p
        v-else
        class="ml-2">
        {{location_3.replace('-', ' ')}}
      </p>
    </div>

    <!-- booking -->
    <div
      v-if="booking !== null"
      class=" title-panel-mobile-status is-hidden-desktop text-basier is-flex"
      >
      <div class="is-flex">
        <img
          @click="historyBack"
          height="100%"
          width="13px"
          class="mr-5"
          src="@/assets/images/panel/navbar/back-header.svg"
          alt="">
        <p
          v-if="bookingProfessional !== null">
          {{location_1}} / {{user.firstname}} {{user.lastname}} / {{bookingProfessional.date}}
        </p>
        <p
          v-if="bookingCliente !== null">
          {{location_1}} / {{user.firstname}} {{user.lastname}} / {{bookingCliente.date}}
        </p>
      </div>
    </div>

    <!-- header public profile mobile -->
    <div
      v-if="visitingProfile && showScroll"
      class="title-panel-mobile is-hidden-desktop text-basier is-flex">
        <a href="/panel/me/edit-me">
          <img
            height="100%"
            width="13px"
            class="mr-5"
            src="@/assets/images/panel/navbar/back-header.svg"
            alt="">
        </a>
        <p class="text-basier">{{user.firstname}} {{user.lastname}}</p>
    </div>

  <!-- Avatar -->
  <AvatarNavBar
    class="avatar_navbar_panel"
    :class="(booking !== null) ? 'is-hidden-touch' : ''"
    id="avatar_navbar"
    :avatar="user.avatar"
    :showp="showp"
    :passwordAccount="passwordAccount"
    :email="user.email"
    :location_2="location_2"
    :location_1="location_1"
    :visitingProfile="visitingProfile"
    :approved="approved"
  ></AvatarNavBar>

  <!-- breadCrumbs -->
  <!-- <div
    v-if="location_1 !== ''" class="breadcrumbs is-hidden-touch" :style="location_3 && booking !== null && bookingProfessional ? 'background: #fff!important;' : ''">
    <div
      class="breadcrumbs-container-1 is-flex"> -->

      <!-- IconEmitus -->
      <!-- <img
        style="width: 8.5px; height: 15px;"
        src="@/assets/images/panel/navbar/icon_emitus.svg"
        alt="icon emitus"> -->

      <!-- Primer nivel -->
      <!-- <div
        v-if="location_1 && booking === null" :class="location_2 ? 'breadCrumbs-opacity' : ''"
        class="is-flex"
        style="gap: 25px;">
        <img
          src="https://emituscdn.com/arrow-w-breadCrumbs.svg"
          alt="arrow">
        <p
          class="
          text-basier
          white
          is-mini-small-text
          text-tranformation-capitalize">
          {{location_1}}
        </p>
      </div> -->
      <!-- Segundo nivel -->
      <!-- <div
        @click="clickLocation_2BreadCrumbs"
        v-if="location_2 !== 'me' && booking === null" :class="location_3 ? 'breadCrumbs-opacity' : ''"
        class="is-flex"
        style="gap: 25px;">
        <img src="https://emituscdn.com/arrow-w-breadCrumbs.svg" alt="arrow">
        <p
          class="
          text-basier
          white
          is-mini-small-text"
          >{{location_2}}</p>
      </div> -->
      <!-- tercer nivel -->
      <!-- <div
        v-if="location_3 && booking === null" :class="location_4 ? 'breadCrumbs-opacity' : ''"
        class="is-flex"
        style="gap: 25px;">
        <img
          src="https://emituscdn.com/arrow-w-breadCrumbs.svg"
          alt="arrow">
        <p
          v-if="location_3 === 'edit-me'"
          class="white is-mini-small-text">
          Profile
        </p>
        <p
          v-else-if="location_3 === 'online-sessions'">
          Monetize
        </p>
        <p
          v-else class="text-basier">
          {{location_3.replace('-', ' ')}}
        </p>
      </div> -->
      <!-- cuarto nivel -->
      <!-- <div
        v-if="location_4 && booking === null"
        class="is-flex"
        style="gap: 25px;">
        <img
          src="https://emituscdn.com/arrow-w-breadCrumbs.svg"
          alt="arrow">
        <p
          class="text-basier">
          {{location_4}}
        </p>
      </div> -->

      <!-- Segundo nivel de statusbooking -->
      <!-- <div
        v-if="location_2 && booking !== null"
        class="is-flex"
        style="gap: 25px;">
        <img
          src="https://emituscdn.com/arrow-w-breadCrumbs.svg"
          alt="arrow">
        <p
          @click="clickLocation_2BreadCrumbsEditBook"
          class="text-basier">
          edit booking
        </p>
      </div> -->
      <!-- tercer nivel de statusbooking -->
      <!-- <div
        v-if="location_3 && booking !== null && bookingProfessional"
        class="is-flex"
        style="gap: 25px;">
        <img
          src="https://emituscdn.com/arrow-w-breadCrumbs.svg"
          alt="arrow">
        <p
          class="text-basier">
          {{user.firstname}} {{user.lastname}} | {{bookingProfessional.date}}
        </p>
      </div>
      <div
        v-if="location_3 && booking !== null && bookingCliente"
        class="is-flex"
        style="gap: 25px;">
        <img
          src="https://emituscdn.com/arrow-w-breadCrumbs.svg"
          alt="arrow">
        <p
          class="text-basier">
          {{user.firstname}} {{user.lastname}} | {{bookingCliente.date}}
        </p>
      </div> -->
    <!-- </div>
  </div> -->

  </div>

</template>

<script>
import AvatarNavBar from '@/components/panel/avatarNavbar'
import firebase from 'firebase/app'
import ProfileService from '@/services/ProfileService'
import UserService from '@/services/UserService'
// import ContactInfo from '@/components/panel/contactInfo'
import 'firebase/auth'

export default {

  name: 'NavBarw',

  components: {
    AvatarNavBar
    // ContactInfo
  },

  data () {
    return {
      user: {},
      profile: {},
      profilesRaw: null,
      profiles: null,
      authUser: null,
      resp: '',
      location: '',
      location_1: '',
      location_2: 'me',
      location_3: 'online-sessions',
      location_4: '',
      passwordAccount: false,
      ModalActive: false,
      showEmail: false,
      booking: null,
      usert: {},
      bookingProfessional: null,
      bookingCliente: null,
      visitingProfile: false,
      showScroll: false,
      profileActive: null,
      showp: false,
      approved: false,
      selected: false,
      showBilletera: true

    }
  },
  watch: {
    authUser: async function () {
      if (this.authUser) {
        // Show passwordAccount
        if (this.authUser.providerData[0].providerId === 'password') {
          this.passwordAccount = true
        }
        // Show
        setTimeout(() => {
          this.showActiveSearch()
        }, 2000)
      }
    },
    // Breadcrumbs
    '$route' () {
      this.notify.$on('booking_', data => {
        this.booking = data
        // console.log(this.booking, '<<<<<<<<-<<<<<')
      })
      this.notify.$on('booking_professional', data => {
        this.bookingProfessional = data
      })
      this.notify.$on('booking_cliente', data => {
        this.bookingCliente = data
      })
      // console.log(this.location_2)
      this.location = window.location.pathname
      this.location_1 = window.location.pathname.split('/')[1]
      this.location_2 = window.location.pathname.split('/')[2]
      this.location_3 = window.location.pathname.split('/')[3]
      this.location_4 = window.location.pathname.split('/')[4]
    }
  },
  async created () {
    firebase.auth().onAuthStateChanged(user => {
      this.authUser = user
      if (this.authUser) {
        firebase.auth().currentUser.getIdToken(true)
          .then(async idToken => {
            // console.log(idToken)
            try {
              await ProfileService.getCurrentProfile(idToken)
                .then(async profile => {
                  this.profile = profile.data
                  this.showp = profile.data.show
                  this.approved = profile.data.approved
                })
              await ProfileService.getAllPublicProfiles()
                .then(profiles => {
                  this.profilesRaw = profiles.data
                  this.profiles = this.profilesRaw
                })
            } catch (error) {
              console.log('error get profile', error)
            }
          })
        firebase.auth().currentUser.getIdToken(true)
          .then(async idToken => {
            try {
              await UserService.getCurrentUser(idToken)
                .then(async user => {
                  this.user = user.data
                })
            } catch (error) {
              console.log('error get user', error)
            }
          })
      }
    })
    this.notify.$on('showpNavbar', (data) => {
      this.showp = data
    })
    this.notify.$on('visitingPublicProfile', () => {
      // console.log('estoy visitando un perfil')
      this.visitingProfile = true
    })
    this.notify.$on('destroyedVisitingPublicProfile', () => {
      this.visitingProfile = false
    })
    // Get User form this.notify
    this.notify.$on('updateUser', (user) => {
      // console.log('qqqqqqqqqq --->>>')
      this.usert = user
      if (typeof this.usert.mangopayuserid !== 'undefined' && this.usert.mangopayuserid.length > 0) {
        this.showBilletera = true
      } else {
        this.showBilletera = false
      }
    })
    this.notify.$on('updateAuthUser', (user) => {
      // console.log('44444 --->>>')
      this.authUser = user
    })
    this.notify.$on('updateProfile', (profile) => {
      // console.log('888888 --->>>')
      this.profile = profile
    })
    this.notify.$on('gato', user => {
      // console.log('gggggggg --->>>')
      this.user = user
    })
    this.notify.$on('hiddeNavBarMobileStatusBooking', () => {
      document.getElementById('nav_bar').classList.add('hidde-navBar-mobile')
    })
    this.notify.$on('notificaionHiddeNavBarMobile', user => {
      document.getElementById('nav_bar').classList.add('hidde-navBar-mobile')
    })
    this.notify.$on('destroyedNotificaionHiddeNavBarMobile', user => {
      document.getElementById('nav_bar').classList.remove('hidde-navBar-mobile')
    })
    this.notify.$on('hiddeNavbarPayBank', () => {
      document.getElementById('nav_bar').classList.add('hidde-navbar-resp-bank')
    })
    // Ask for data to App
    await this.notify.$emit('giveMeData')
    // BreadCrumbs + Hide NavBar when I'm looking at a profile
    this.location = window.location.pathname
    this.location_1 = window.location.pathname.split('/')[1]
    this.location_2 = window.location.pathname.split('/')[2]
    this.location_3 = window.location.pathname.split('/')[3]

    window.addEventListener('scroll', this.showNavbarMobileProfileLogged)
  },
  mounted () {
    this.inputSearchNavbar()
  },
  methods: {
    pushListing () {
      this.$router.push('/')
    },
    inputSearchNavbar () {
      if (document.getElementById('searchBarNavbar')) {
        const searchBar = document.getElementById('searchBarNavbar')
        searchBar.addEventListener('keyup', (e) => {
        })
      }
    },
    showNavbarMobileProfileLogged () {
      const resp = window.scrollY
      if (resp > 900) {
        this.showScroll = true
      } else {
        this.showScroll = false
      }
    },
    showActiveSearch () {
      if (this.location_1 === this.user.username) {
        // document.getElementById('nav_bar').classList.add('hidde-navBar-mobile')
        document.getElementById('Search').classList.add('select-location')
      } else {
        if (document.getElementById('Search')) {
          document.getElementById('Search').classList.remove('select-location')
        }
      }
    },
    historyBack () {
      history.back(-1)
      document.getElementById('nav_bar').classList.remove('hidde-navBar-mobile')
      if (this.booking !== null) {
        this.booking = null
        this.bookingProfessional = null
        this.bookingCliente = null
      }
    },
    clickLocation_2BreadCrumbs () {
      this.$router.push(`/${this.location_1}/${this.location_2}`)
    },
    clickLocation_2BreadCrumbsEditBook () {
      this.$router.push('/panel/booking')
    }
  },
  beforeDestroy () {
    this.notify.$off('updateAuthUser')
    this.notify.$off('updateUser')
    this.notify.$off('updateProfile')
    this.notify.$off('booking_')
    this.notify.$off('booking_professional')
    this.notify.$off('booking_cliente')
    this.notify.$off('hiddeNavBarMobileStatusBooking')
    this.notify.$off('notificaionHiddeNavBarMobile')
    this.notify.$off('destroyedNotificaionHiddeNavBarMobile')
    this.notify.$off('gato')
    this.notify.$off('visitingPublicProfile')
    this.notify.$off('destroyedVisitingPublicProfile')
  },
  destroyed () {
    window.removeEventListener('scroll', this.showNavbarMobileProfileLogged)
  }
}

</script>

<style>
#search_id_navbar .control.has-icons-left .input {
  background: rgba(255, 255, 255, 0.1);
  border: 0.8px solid rgba(255, 255, 255, 0.51);
  border-radius: 19px;
}

a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
  background-color: #000 !important;
}

.breadCrumbs-opacity {
  opacity: 0.5;
}
.avatar_navbar_panel {
  z-index: 11;
  position: fixed;
  top: 19px;
  right: 25px;
}
.is-active {
  display: block;
}
.is-desactive {
  display: none;
}
.hidde-navbar-resp-bank {
  display: none!important;
}
.breadcrumbs {
  margin: 60px auto 0px 0px;
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;
}
.breadcrumbs-container-1 {
  display: flex;
  align-items: center;
  gap:25px;
  margin: auto;
  border-bottom: 1px solid rgba(246, 246, 246, 0.1);
  padding-left: 5%;
  background-color: #0B0C33;
  height: 50px;
  padding: 20px 0 15px 5%;
}
.container-navbar-item {
  padding: 4px 0px;
}
.container-navbar-item a:focus {
  background: transparent;
  outline: none;
  color: transparent;
}
.container-navbar-item a:link {
  background: transparent;
  outline: none;
  color: transparent;
}
.container-navbar-item a:active {
  background: transparent;
  outline: none;
  color: transparent;
}
.container-navbar-item a:visited {
  background: transparent;
  outline: none;
  color: transparent;
}
.navbar-end {
  width: 0!important;
}
.text-icon {
  color: #fff;
  font-weight: 500;
}

.Modal {
  background-color: #ffffff;
  height: 80vh;
  border-radius: 10px;
}

/* .router-link-exact-active p {
  color: #00D6CB!important;
}
.router-link-active p {
  color: #00D6CB!important;
  font-weight: 700;
} */
/* .si-select {
  display: none;
}
.router-link-exact-active .si-select {
  display: block;
}
.router-link-active .si-select {
  display: block;
}
.router-link-exact-active .no-select {
  display: none;
}
.router-link-active .no-select {
  display: none;
} */
.flex-direction-icon-navbar {
  flex-direction: column;
}
.nav_bar_profile {
  position: fixed!important;
  width: 100%!important;
  z-index: 10!important;
  background: #030728!important;
  height: 59px;
}

.logo-abogadus-nav-bar-me {
  margin-top: 10px;
  width: 110px;
}

@media (max-width: 1024px) {
  .select-icon p {
    color:#6E3DEA;
  }
  .hidde-navBar-mobile {
    display: none!important;
  }
  .nav_bar_profile {
    bottom: 0!important;
  }

  .navbar-start {
    justify-content: space-evenly !important;
    width: 100% !important;
    padding: 0px 4px;
  }
  .container-navbar-item {
    width: 100%;
    justify-content: space-around;
  }

  .title-panel-mobile {
    text-transform: capitalize;
    padding: 21px 0 10px 19px;
    font-family: "Basier Circle Bold";
    font-weight: bold;
    font-size: 17px;
    color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    background: #131212;
    z-index: 5;
    /*border: 1px solid #ECECEC;*/
  }

  .sub-title-panel {
    text-transform: capitalize;
    font-size: 16px;
    padding: 25px 0 13px 5%;
    border-bottom: 1px solid #F3F3F3;
  }

  .sub-title-panel p {
    margin-left: 10px;
    margin-top: -5px;
  }

  .sub-title-panel img {
    height: 14px;
  }

  .title-panel-mobile-status {
    border: 1px solid #ECECEC ;
    padding: 21px 0 10px 19px;
    text-transform: capitalize;
    font-size: 13px;
    color: #0C0D34;
    /*border: 1px solid #ECECEC;*/
  }
}
@media (max-width: 1024px) {
  .navbar-menu {
    background: #1b1b1b!important;
    padding: 0px!important;
  }
  .container-navbar-item .router-link-exact-active {
    border-top: 2px solid #fff;
  }
  .container-navbar-item {
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .flex-direction-icon-navbar {
    flex-direction: row;
    gap: 8px;
  }
  .container-navbar-item .router-link-exact-active {
    border-bottom: 2px solid #fff;
  }
  .navbar-menu {
    background: #1b1b1b!important;
  }
  .container-icon-navbar {
    margin-right: 78px;
    height: 100%;
  }
  .container-icon-navbar a {
    justify-content: center;
  }
  .item-width {
    width: 80px;
  }
  .nav_bar_profile {
    top: 0!important;
  }
  .navbar-start {
    width: 100%!important;
    display: flex!important;
    justify-content: flex-end!important;
  }
  .text-icon {
    color: #fff;
  }

  a.navbar-item:hover {
    background-color: #303033 !important;
  }
  .container-icon-navbar .router-link-active, .a.navbar-item:active, a.navbar-item:focus {
    background-color: #000000 !important;
    transition-timing-function: ease-in;
    transition-duration: 0.1s;
  }

  .avatar_navbar_panel {
    top: 11px;
  }
}

@media (min-width: 959px) {
  .container-content-create-account {
    margin-left: 120px;
    margin-right: 0;
  }
}
</style>
